import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  destroyLocalStorageItem,
  getLocalStorageItem,
  saveLocalStorageItem,
} from "@/core/services/localStorage";

import router from "@/router";
import { SET_HAS_THEME_SET } from "@/core/services/store/main.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_AUTH2 = "setUser2";
export const SET_PASSWORD = "setPassword";
export const SET_WAREHOUSE = "setWarehouse";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  selectedWareHouse: Number(getLocalStorageItem("selected_warehouse_id")),
};

const getters = {
  getSocketData(state) {
    return state.user.socket;
  },
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isClient(state) {
    // return state.user.is_client;
    return state.user.data.is_client;
  },
  getUserWareHouses(state) {
    return state.user.warehouses;
  },
  getSelectedWarehouse(state) {
    return state.selectedWareHouse;
  },
  organizationSystemCode(state) {
    return state.user.data.organization_system_code;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    // context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post("/auth/login", credentials)
        .then(({ data }) => {
          if (data.logged_in) {
            Swal.fire({
              title: "Warning",
              text: data.message,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Log in!",
            }).then((result) => {
              if (result.isConfirmed) {
                context.commit(SET_PAGE_LOADING, true);
                const data = { ...credentials, continue_login: 1 };
                context.dispatch(LOGIN, data);
              } else {
                context.commit(PURGE_AUTH);
                resolve(data);
              }
            });
          } else {
            if (data.data.status == "Active") {
              context.commit(SET_AUTH, data);
              router.push({ name: "dashboard.dashboardDelivery" });
              resolve(data);
            } else {
              context.commit(PURGE_AUTH);
              resolve(data);
            }
          }
          // if (data.data.status == "Active") {
          //   context.commit(SET_AUTH, data);
          //   router.push({ name: "dashboard.dashboardDelivery" });
          //   resolve(data);
          // } else {
          //   context.commit(PURGE_AUTH);
          //   resolve(data);
          // }
        })
        .catch((error) => {
          resolve(error);
        });
    });
  },
  [LOGOUT](context) {
    let data = {};
    try {
      if (this.state.auth.user.data.id) {
        data = { user_id: this.state.auth.user.data.id };
        ApiService.post("/auth/logout", data).then(() => {
          context.commit(PURGE_AUTH);
        });
      } else {
        context.commit(PURGE_AUTH);
      }
      context.commit(SET_WAREHOUSE, null);
    } catch {
      context.commit(PURGE_AUTH);
    }
    // context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      context.commit(SET_PAGE_LOADING, true);
      // console.log("need to load user data");
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("verify")
          .then((response) => {
            // console.log("verify success", data);
            context.commit(SET_AUTH2, response.data);
            // context.commit(SET_PAGE_LOADING, false);
            if (context.getters.getHasThemeSet < 2) {
              context.commit(SET_HAS_THEME_SET, 1);
            }
            resolve(response);
          })
          .catch((response) => {
            // context.commit(SET_PAGE_LOADING, false);
            context.commit(PURGE_AUTH);
            reject(response);
            // console.log("errors are ", this.state.errors);
          });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    const password = payload;

    return ApiService.post("profile/password/update", password)
      .then(({ data }) => {
        // console.log("password change data is ", data);
        context.commit(SET_PASSWORD, data);
        context.commit(SET_PAGE_LOADING, false);
        return true;
      })
      .catch(() => {
        context.commit(SET_PAGE_LOADING, false);
        return false;
      });
  },
};

const mutations = {
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    // console.log(user);
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_AUTH2](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    // console.log(user);
    state.errors = {};
    // JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    destroyLocalStorageItem("selected_warehouse_id");
  },
  [SET_WAREHOUSE](state, warehouse) {
    // console.log("ware", typeof warehouse);
    state.selectedWareHouse = warehouse;
    saveLocalStorageItem("selected_warehouse_id", warehouse);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
