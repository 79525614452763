import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const GET_SORT_PLAN_DATA = "GET_SORT_PLAN_DATA";
export const SORT_PLAN_CREATE_API = "callWESSORTPLANCreateAPI";

// mutation types
export const SET_CHUTES = "setWESSORTPLANChutes";
export const SET_PLANS = "setWESSORTPLANPlans";
export const SET_LOCATIONS = "setWESSORTPLANLocations";
export const SET_CATEGORIES = "setWESSORTPLANCategories";
export const ADD_REMOVE_CHUTE_TO_SELECTED_PLAN =
  "addAndRemoveChuteToSelectedPlan";
export const ADD_REMOVE_LOCATION_TO_SELECTED_CHUTE =
  "addAndRemoveLocationToSelectedChute";
export const ADD_REMOVE_CATEGORY_TO_SELECTED_LOCATION =
  "addAndRemoveCategoryToSelectedLocation";
export const SET_SELECTED_PLAN_DETAILS = "setWESSORTPLANSelectedPlanDetails";
export const SELECT_ALL_CHUTES = "setWESSORTPLANSelectAllChutes";
export const SELECT_ALL_LOCATIONS = "setWESSORTPLANSelectAllLocations";
export const SELECT_ALL_CATEGORIES = "setWESSORTPLANSelectAllCategories";
export const SET_CREATE_DIALOG_SERVER_DATA =
  "setCreateDialogServerDataForWESSORTPLAN";

const state = {
  plans: [],
  chutes: [],
  locations: [],
  categories: [],
  labels: {
    planLabel: "Create a plan",
    chuteLabel: "Select a plan",
    locationLabel: "Select a chute",
    categoryLabel: "Select a location",
  },
  selectedPlanDetails: {},
  serverData: {},
};

const getters = {
  getWESSORTPLANPlans(state) {
    return state.plans;
  },
  getWESSORTPLANChutes(state) {
    return state.chutes;
  },
  getWESSORTPLANLocations(state) {
    return state.locations;
  },
  getWESSORTPLANCategories(state) {
    return state.categories;
  },
  getWESSORTPLANLabels(state) {
    return state.labels;
  },
  getWESSORTPLANSelectedPlanDetails(state) {
    return state.selectedPlanDetails;
  },
  getWESSORTPLANServerData(state) {
    return state.serverData;
  },
};

const actions = {
  [GET_SORT_PLAN_DATA](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/wes/wesSortPlan")
        .then(({ data }) => {
          // console.log(data);
          context.commit(SET_PLANS, data.data.plans);
          context.commit(SET_CHUTES, data.data.chutes);
          context.commit(SET_LOCATIONS, data.data.locations);
          context.commit(SET_CATEGORIES, data.data.categories);
          // context.commit(SET_ACTIONS, { field_id: null, actions: null });
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [SORT_PLAN_CREATE_API](context) {
    return new Promise((resolve) => {
      ApiService.post("/wes/wesSortPlan/create")
        .then(({ data }) => {
          // console.log("create data", data);
          context.commit(SET_CREATE_DIALOG_SERVER_DATA, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_CREATE_DIALOG_SERVER_DATA](state, data) {
    state.serverData = data;
  },
  [SELECT_ALL_CHUTES](state, selectAll) {
    const { plan_index } = state.selectedPlanDetails;
    if (selectAll) {
      state.plans[plan_index].chutes = state.chutes.map((chute) => {
        chute.is_selected = true;

        return {
          ...chute,
          locations: [],
          // locations: chute?.locations.map((location) => ({
          //   ...location,
          //   is_selected: true,
          //   categories: location?.categories.map((category) => ({
          //     ...category,
          //     is_selected: true,
          //   })),
          // })),
        };
      });
    } else {
      state.chutes.forEach((chute) => {
        chute.is_selected = false;
      });

      state.plans[plan_index].chutes.splice(0);
    }
  },
  [SELECT_ALL_LOCATIONS](state, selectAll) {
    const { plan_index, chute_index } = state.selectedPlanDetails;
    if (selectAll) {
      state.plans[plan_index].chutes[
        chute_index
      ].locations = state.locations.map((location) => {
        location.is_selected = true;

        return {
          ...location,
          categories: [],
          // categories: location.categories.map((category) => ({
          //   ...category,
          //   is_selected: true,
          // })),
        };
      });
    } else {
      state.locations.forEach((chute) => {
        chute.is_selected = false;
      });

      state.plans[plan_index].chutes[chute_index].locations.splice(0);
    }
  },
  [SELECT_ALL_CATEGORIES](state, selectAll) {
    const {
      plan_index,
      chute_index,
      location_index,
    } = state.selectedPlanDetails;
    if (selectAll) {
      state.plans[plan_index].chutes[chute_index].locations[
        location_index
      ].categories = state.categories.map((category) => {
        category.is_selected = true;

        return {
          ...category,
          is_selected: true,
        };
      });
    } else {
      state.categories.forEach((chute) => {
        chute.is_selected = false;
      });

      state.plans[plan_index].chutes[chute_index].locations[
        location_index
      ].categories.splice(0);
    }
  },
  [ADD_REMOVE_CHUTE_TO_SELECTED_PLAN](state, payload) {
    const { selectedField, forAdding } = payload;
    const { plan_index } = state.selectedPlanDetails;

    if (forAdding) {
      state.plans[plan_index].chutes.push({
        id: selectedField.id,
        name: selectedField.name,
        is_selected: true,
        locations: [],
        // locations: state.locations.map((location) => ({
        //   ...location,
        //   categories: [],
        //   is_selected: false,
        // })),
      });
    } else {
      const foundIndex = state.plans[plan_index].chutes.findIndex(
        (chute) => chute.id === selectedField.id
      );

      if (foundIndex > -1) {
        state.plans[plan_index].chutes.splice(foundIndex, 1);
      }
    }
  },
  [ADD_REMOVE_LOCATION_TO_SELECTED_CHUTE](state, payload) {
    const { selectedField, forAdding } = payload;
    const { plan_index, chute_index } = state.selectedPlanDetails;

    if (forAdding) {
      state.plans[plan_index].chutes[chute_index].locations.push({
        id: selectedField.id,
        name: selectedField.name,
        is_selected: true,
        categories: [],
        // categories: state.categories.map((category) => ({
        //   ...category,
        //   is_selected: false,
        // })),
      });
    } else {
      const foundIndex = state.plans[plan_index].chutes[
        chute_index
      ].locations.findIndex((location) => location.id === selectedField.id);

      if (foundIndex > -1) {
        state.plans[plan_index].chutes[chute_index].locations.splice(
          foundIndex,
          1
        );
      }
    }
  },
  [ADD_REMOVE_CATEGORY_TO_SELECTED_LOCATION](state, payload) {
    const { selectedField, forAdding } = payload;
    const {
      plan_index,
      chute_index,
      location_index,
    } = state.selectedPlanDetails;

    if (forAdding) {
      state.plans[plan_index].chutes[chute_index].locations[
        location_index
      ].categories.push({
        id: selectedField.id,
        name: selectedField.name,
        is_selected: true,
      });
    } else {
      const foundIndex = state.plans[plan_index].chutes[chute_index].locations[
        location_index
      ].categories.findIndex((category) => category.id === selectedField.id);

      if (foundIndex > -1) {
        state.plans[plan_index].chutes[chute_index].locations[
          location_index
        ].categories.splice(foundIndex, 1);
      }
    }
  },
  [SET_SELECTED_PLAN_DETAILS](state, obj) {
    state.selectedPlanDetails = {
      ...state.selectedPlanDetails,
      ...obj,
    };
  },
  [SET_PLANS](state, data) {
    state.plans = [...data];
    state.selectedPlanDetails = {};
  },
  [SET_CHUTES](state, data) {
    state.chutes = data.map((item) => ({
      ...item,
      is_selected: false,
    }));
  },
  [SET_LOCATIONS](state, data) {
    // state.locations = [...data];
    state.locations = data.map((item) => ({
      ...item,
      is_selected: false,
    }));
  },
  [SET_CATEGORIES](state, data) {
    // state.categories = [...data];
    state.categories = data.map((item) => ({
      ...item,
      is_selected: false,
    }));
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
